import { PageContainer, TranslatedTable } from "../../components"
import { Button, Col, Row } from "reactstrap"
import React from "react"
import { withTranslation } from "react-i18next"
import { fetchResources, setlistsize } from "../../actions"
import { connect } from "react-redux"
import moment from "moment"

class Invoices extends React.Component {
  componentDidMount = () => {
    this.props.fetchResources({ resource: "invoices" })
    this.props.fetchResources({ resource: "invoicesbilleddb" })
  }

  fetchBilled = async () => {
    this.props.fetchResources({ resource: "invoicesbilled" })
  }

  render() {
    const { t, invoices, invoicesbilled, invoicesbilleddb } = this.props

    let data = [...invoices.invoices]
    if (invoicesbilleddb.invoicesbilleddb.length !== 0 && !invoices.fetching) {
      invoicesbilleddb.invoicesbilleddb.forEach((billed) => {
        const invoice = data.find((invoice) => invoice.id === billed.invoiceId)
        const billedAmount = billed.billed || 0
        const calculated = billed.total || 0
        let margin = 0
        let calculatedMargin = 0
        if (invoice == null) {
          console.log(invoices.invoices, "billed", billed)
          return
        }

        let charges = invoice && invoice.totalCharges ? invoice.totalCharges : 0
        if (invoice.id[0] === "G") {
          charges /= 1.24
        }

        if (billedAmount !== 0 && billedAmount !== null) {
          margin =
            Math.round(((billedAmount - charges) / billedAmount) * 10000) / 100
        }
        if (calculated !== 0 && calculated !== null) {
          calculatedMargin =
            Math.round(((calculated - charges) / calculated) * 10000) / 100
        }

        invoice.billedAmount = `${
          Math.round(100 * billedAmount) / 100 || 0
        } (${margin} %)`
        invoice.calculated = `${
          Math.round(100 * calculated) / 100 || 0
        } (${calculatedMargin} %)`
      })
    }
    // for now we will overwrite the invoicesbilleddb data since its not sure if it will work with very old invoices
    if (invoicesbilled.invoicesbilled.length !== 0) {
      invoicesbilled.invoicesbilled.forEach((billed) => {
        const invoice = data.find((invoice) => invoice.id === billed.id)
        let billedAmount = billed.total
        let margin = 0
        if (billedAmount !== 0 && billedAmount !== null) {
          let charges =
            invoice && invoice.totalCharges ? invoice.totalCharges : 0.001
          margin =
            Math.round(((billedAmount - charges) / charges) * 10000) / 100
        }
        invoice.billedAmount = `${
          Math.round(100 * billedAmount) / 100 || 0
        } (${margin} %)`
      })
    }
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        Cell: (props) => (
          <a href={`/#/invoices/${props.original.id}`}>{props.original.id}</a>
        ),
      },
      {
        Header: t("_Date"),
        accessor: "invoiceDate",
        Cell: (props) =>
          moment(props.original.invoiceDate).format("MMMM Do YYYY"),
      },
      {
        Header: t("_total"),
        id: "totalCharges",
        accessor: (row) => {
          if (row.id[0] === "G") {
            return row.totalCharges / 1.24
          }
          return row.totalCharges
        },
      },
      {
        Header: t("_paid"),
        accessor: "paidAmount",
      },
      {
        Header: `${t("_calculated")}`,
        accessor: "calculated",
      },
      {
        Header: `${t("_billed")}`,
        accessor: "billedAmount",
      },
    ]

    return (
      <PageContainer title={t("_invoices")}>
        <Row>
          <Col sm="12">
            <Button
              className="float-right"
              color="primary"
              onClick={this.fetchBilled}
              disabled={
                this.props.invoices.fetching ||
                this.props.invoicesbilled.fetching
              }
            >
              {t("_fetchBilled")}
            </Button>
          </Col>
        </Row>

        <hr />

        <TranslatedTable
          data={data}
          fetching={
            this.props.invoices.fetching || this.props.invoicesbilled.fetching
          }
          handleresize={this.props.setlistsize}
          listSize={this.props.list.listSetting}
          columns={columns}
          filterable={true}
        />
        <hr />
      </PageContainer>
    )
  }
}

export default connect((s) => s, { fetchResources, setlistsize })(
  withTranslation()(Invoices)
)
