import React, { useState, useEffect } from "react"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { TranslatedTable } from "../../components"
import { connect } from "react-redux"
import { setlistsize, fail, fetchResources } from "../../actions"
import {
  Button,
  Input,
  Modal,
  ModalBody,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { SelectInputComponent, ConfirmActionModal } from "../../components"
import { combineByField, countItems, removeDuplicates } from "../../lib"

import moment from "moment"

class InvoiceDetail extends React.Component {
  state = {
    items: [],
    originalItems: [],
    customerTotals: [],
    statuses: [],
    fetching: true,
    customerId: "all",
    modalOpen: false,
    modalMessage: "",
    modalOptions: {},
    saving: false,
    savingRows: false,
    force: false,
    calculating: false,
    filter: "",
    selectedRows: [],
    selectedAll: false,
    confirmActionModalOpen: false,
    isDropdownOpen: false,
    billerChosen: "",
  }
  constructor(props) {
    super(props)
    this.reactTable = React.createRef()
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.chooseBiller = this.chooseBiller.bind(this)
  }

  componentDidMount = async () => {
    this.fetchItems()
    this.props.fetchResources({ resource: "customers", force: true })
  }
  fetchItems = async () => {
    axios
      .get(`/invoices/${this.props.invoice.id}`)
      .then(({ data }) => {
        this.setState({
          originalItems: JSON.parse(JSON.stringify(data.items)),
          items: data.items,
          customerTotals: data.customerTotals,
          statuses: data.statuses,
          fetching: false,
        })
      })
      .catch((error) =>
        this.props
          .fail(null, error)
          .then(() => this.setState({ fetching: false }))
      )
  }

  toggleDropdown() {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen,
    })
  }

  chooseBiller(biller, event) {
    event.stopPropagation()
    this.setState({
      billerChosen: biller,
    })
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  calculateRows = async (customerIds) => {
    // this could be a task?
    this.setState({ calculating: true })
    try {
      let customerIdsToServer = customerIds
      if (customerIds !== "all") {
        // Filter customerIds to only include parent customer IDs
        customerIdsToServer = customerIds.filter((id) =>
          this.state.customerTotals.some(
            (customer) => customer.customerId === id
          )
        )
      }
      await axios.post(`/invoice/${this.props.invoice.id}/calculate`, {
        customerIds: customerIdsToServer,
      })
      // fetch new items
      await this.fetchItems()
      this.setState({ selectedRows: [], selectedAll: false })
    } catch (err) {
      this.setState({
        modalOpen: true,
        modalMessage: "_calculate_invoice_error",
        modalOptions: {},
        saving: false,
      })
    } finally {
      this.setState({ calculating: false })
    }
  }

  flattenAndFormatPayload = (subscriptions) => {
    const updatedSubscriptions = { ...subscriptions }

    Object.keys(updatedSubscriptions).forEach((customerId) => {
      const customerSubscriptions = updatedSubscriptions[customerId]

      if (customerSubscriptions === undefined) {
        delete updatedSubscriptions[customerId]
      } else {
        updatedSubscriptions[customerId] = customerSubscriptions.filter(
          (subId) => subId !== customerId
        )
      }
    })

    return updatedSubscriptions
  }

  submitBilling = async () => {
    this.setState({ saving: true })
    try {
      const {
        data: { statuses },
      } = await axios.post(
        `/invoice/${this.props.invoice.id}/save?force=${this.state.force}&biller=${this.state.billerChosen}`,
        {
          customerIds: this.state.selectedRows,
        }
      )

      await this.fetchItems()
      this.setState({
        modalOpen: true,
        modalMessage: "_netsuiteSaved",
        modalOptions: {
          successes: countItems(statuses, "billed", "status"),
          total: statuses.length,
        },
        saving: false,
        selectedAll: false,
        selectedRows: [],
      })
    } catch (err) {
      console.log(err)
      this.setState({
        modalOpen: true,
        modalMessage: "_fivaldiError",
        modalOptions: {},
        saving: false,
        selectedAll: false,
        selectedRows: [],
      })
    } finally {
      setTimeout(() => {
        this.setState({ modalOpen: false })
      }, 2000)
    }
  }

  testInvoice = async () => {
    // Let's check if the selected customer has any invoices this month
    if (
      this.state.customerId !== "all" &&
      !this.state.customerTotals
        .map((customer) => customer.customerId)
        .includes(this.state.customerId)
    ) {
      this.setState({
        modalOpen: true,
        modalMessage: "_fivaldiNoInvoices",
        modalOptions: {},
      })
      setTimeout(() => {
        this.setState({ modalOpen: false })
      }, 2000)
      return
    }

    this.setState({ saving: true })
    try {
      const response = await axios.get(
        `testInvoiceXML/${this.props.invoice.id}&${this.state.customerId}`
      )
      console.log(response)
      if (response.length > 0)
        this.setState({
          modalOpen: true,
          modalMessage: "Console error log added",
          modalOptions: {},
          saving: false,
        })
      else
        this.setState({
          modalOpen: true,
          modalMessage: "Successfull",
          modalOptions: {},
          saving: false,
        })
    } catch (err) {
      this.setState({
        modalOpen: true,
        modalMessage: "_xmlError",
        modalOptions: {},
        saving: false,
      })
    } finally {
      setTimeout(() => {
        this.setState({ modalOpen: false })
      }, 2000)
    }
  }

  saveRows = async () => {
    // save all rows
    try {
      // check for modified rows
      const changedRows = {}
      for (const row of this.state.items) {
        const other = this.state.originalItems.find((x) => x._id === row._id)
        if (other) {
          if (
            row.Subtotal !== other.Subtotal ||
            row.ProductName !== other.ProductName
          ) {
            if (!(row.CustomerId in changedRows)) {
              changedRows[row.CustomerId] = []
            }
            changedRows[row.CustomerId].push({
              id: row._id,
              cost: row.Subtotal !== other.Subtotal ? row.Subtotal : undefined,
              ProductName:
                row.ProductName !== other.ProductName
                  ? row.ProductName
                  : undefined,
            })
          }
        }
      }
      if (Object.keys(changedRows).length === 0) {
        console.log("no changes")
        return
      }

      this.setState({ savingRows: true })
      const { data } = await axios.patch(
        `/invoice/${this.props.invoice.id}/rows`,
        {
          rows: changedRows,
        }
      )
      await this.fetchItems()
      this.setState({
        modalOpen: true,
        modalMessage: "_rowsSaved",
        modalOptions: {
          count: Object.keys(changedRows).length,
          modified: data.modifiedCount,
        },
        savingRows: false,
      })
    } catch (err) {
      this.setState({
        modalOpen: true,
        modalMessage: "_saveInvoiceError",
        modalOptions: {},
        savingRows: false,
      })
    }
  }

  render() {
    const { t } = this.props
    let customerTotals = []

    // Loop through the items to aggregate data by customerId
    for (const invoiceRow of this.state.items) {
      if (!(invoiceRow.CustomerId in customerTotals)) {
        customerTotals[invoiceRow.CustomerId] = {
          customerId: invoiceRow.CustomerId,
          companyName: invoiceRow.CustomerName,
          total: 0,
          azure: 0,
          office: 0,
          subscriptions: [], // New property for subscriptions
        }
      }

      // Update the overall totals for the customer
      customerTotals[invoiceRow.CustomerId].total += invoiceRow.Subtotal
      if (invoiceRow.isOffice) {
        customerTotals[invoiceRow.CustomerId].office += invoiceRow.Subtotal
      } else {
        customerTotals[invoiceRow.CustomerId].azure += invoiceRow.Subtotal
      }

      if (invoiceRow.EntitlementDescription) {
        // Find or add the subscription in the subscriptions array
        const subscriptionIndex = customerTotals[
          invoiceRow.CustomerId
        ].subscriptions.findIndex(
          (sub) => sub.subscriptionName === invoiceRow.EntitlementDescription
        )

        if (subscriptionIndex === -1) {
          // If subscription doesn't exist, add it
          customerTotals[invoiceRow.CustomerId].subscriptions.push({
            subscriptionName: invoiceRow.EntitlementDescription,
            total: invoiceRow.Subtotal,
            azure: invoiceRow.isOffice ? 0 : invoiceRow.Subtotal,
            office: invoiceRow.isOffice ? invoiceRow.Subtotal : 0,
          })
        } else {
          // If subscription exists, update the totals
          const subscription =
            customerTotals[invoiceRow.CustomerId].subscriptions[
              subscriptionIndex
            ]
          subscription.total += invoiceRow.Subtotal
          if (invoiceRow.isOffice) {
            subscription.office += invoiceRow.Subtotal
          } else {
            subscription.azure += invoiceRow.Subtotal
          }
        }
      }
    }

    // Convert the object to an array
    customerTotals = Object.values(customerTotals)

    // Ensure all customers have a subscriptions array
    customerTotals.forEach((customer) => {
      if (!customer.subscriptions) {
        customer.subscriptions = [] // Assign an empty array if missing
      }
    })

    if (!this.state.customerTotals) {
      this.setState({ customerTotals })
    }

    const columnsTotals = [
      {
        Header: t("_customer"),
        accessor: "companyName",
      },
      {
        Header: t("_total"),
        accessor: "total",
        width: 120,
        Cell: (props) => (props.value !== null ? props.value.toFixed(2) : 0),
      },
      {
        Header: "O365",
        accessor: "office",
        width: 120,
        Cell: (props) => (props.value !== null ? props.value.toFixed(2) : 0),
      },
      {
        Header: "Azure",
        accessor: "azure",
        width: 120,
        Cell: (props) => (props.value !== null ? props.value.toFixed(2) : 0),
      },
      {
        Header: t("_billedBy"),
        accessor: "billedBy.email",
      },
      {
        Header: t("_billedAt"),
        accessor: "billedAt",
        width: 130,
        Cell: (props) =>
          props.value ? moment(props.value).format("DD.MM.YYYY HH:mm") : "",
      },
      {
        Header: t("_status"),
        accessor: "status",
        width: 100,
        Cell: (props) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {(() => {
                switch (props.value) {
                  case "created":
                    return (
                      <span className="icon bi-plus-circle-fill text-primary m-auto" />
                    )
                  case "billed":
                    return (
                      <span className="icon bi-check-circle-fill text-success m-auto" />
                    )
                  case "failed":
                    return (
                      <span className="icon bi-x-circle-fill text-danger m-auto" />
                    )
                }
                return ""
              })()}
            </div>
          )
        },

        filterMethod: (filter, row) => {
          if (filter.value === "") {
            return true
          }
          return row[filter.id] === filter.value
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : ""}
          >
            <option value="">{t("_all")}</option>
            <option value="created">{t("_created")}</option>
            <option value="billed">{t("_billed")}</option>
            <option value="failed">{t("_failed")}</option>
          </select>
        ),
      },
      {
        Header: t("_errorMessage"),
        accessor: "errorCode",
        width: 230,
        Cell: (props) => {
          const [tooltipOpen, setTooltipOpen] = useState(false)
          const toggle = () => setTooltipOpen(!tooltipOpen)
          const error =
            t("_invoicingError", { returnObjects: true })[props.value] || ""
          const description =
            t("_invoicingErrorDescription", { returnObjects: true })[
              props.value
            ] || ""

          return (
            <>
              <span id={`error-message-${props.index}`}>{error}</span>
              {description && (
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen}
                  target={`error-message-${props.index}`}
                  toggle={toggle}
                >
                  {description}
                </Tooltip>
              )}
            </>
          )
        },
      },
    ]

    const columnsItems = [
      {
        Header: t("_customer"),
        accessor: "CustomerName",
      },
      {
        Header: t("_name"),
        id: "name",
        accessor: (row) =>
          row.ProductName ? row.ProductName : "ProductName not found",

        Cell: (cellInfo) => {
          const [value, setValue] = useState(cellInfo.value)
          useEffect(() => {
            setValue(cellInfo.value, true)
          }, [cellInfo.value])
          return (
            <Input
              disabled={
                this.state.statuses.find(
                  (s) => s.customerId === cellInfo.original.CustomerId
                )?.status === "billed"
              }
              style={{
                outline: "none",
                backgroundColor: "inherit",
                border: "none",
                height: "20px",
                marginY: "auto",
                boxSizing: "border-box",
              }}
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && value !== cellInfo.value) {
                  this.setState({
                    items: this.state.items.map((item) => {
                      if (item._id === cellInfo.original._id) {
                        item.ProductName = value
                      }
                      return item
                    }),
                  })
                }
              }}
              onBlur={(e) => {
                if (value !== cellInfo.value) {
                  this.setState({
                    items: this.state.items.map((item) => {
                      if (item._id === cellInfo.original._id) {
                        item.ProductName = value
                      }
                      return item
                    }),
                  })
                }
              }}
            />
          )
        },
      },
      {
        Header: t("_serviceName"),
        id: "serviceName",
        accessor: (row) => (row.isOffice ? "OnlineServices" : "Azure"),
      },
      {
        Header: t("_chargeType"),
        id: "chargeType",
        accessor: "ChargeType",
        width: 110,
      },
      {
        Header: t("_calcType"),
        id: "calcType",
        accessor: (row) =>
          row.isOffice
            ? "Laskutapaa ei saatavilla"
            : "Laskutapaa ei saatavilla",
      },
      {
        Header: t("_internalUnitPrice"),
        id: "effectiveUnitPrice",
        accessor: "UnitPrice",
        width: 170,
      },
      // TODO: miten lasketaan 'Yksikköhinta' nykyisellä datan rakenteella?
      // {
      //   Header: t("_unitPrice"),
      //   id: "unitPrice",
      //   width: 170,
      //   accessor: (row) =>
      //     Math.round((100000 * row.cost) / (row.quantity || 1)) / 100000,

      //   filterMethod: (filter, row) => {
      //     if (filter.value === "") {
      //       return true
      //     }
      //     if (filter.value === "noProfit") {
      //       return (
      //         row.effectiveUnitPrice > 0 &&
      //         row.unitPrice - // this is the calculated unit price, not the one from the request
      //           row.effectiveUnitPrice <
      //           0
      //       )
      //     }
      //     return true
      //   },
      //   Filter: ({ filter, onChange }) => (
      //     <select
      //       onChange={(event) => onChange(event.target.value)}
      //       style={{ width: "100%" }}
      //       value={filter ? filter.value : "all"}
      //     >
      //       <option value="">{t("_allRows")}</option>
      //       <option value="noProfit">{t("_unprofitableRows")}</option>
      //     </select>
      //   ),
      //   Cell: (cellInfo) => {
      //     const [value, setValue] = useState(cellInfo.value)
      //     useEffect(() => {
      //       setValue(cellInfo.value, true)
      //     }, [cellInfo.value])

      //     return (
      //       <Input
      //         disabled={
      //           this.state.statuses.find(
      //             (s) => s.customerId === cellInfo.original.customerId
      //           )?.status === "billed"
      //         }
      //         style={{
      //           outline: "none",
      //           backgroundColor: "inherit",
      //           border: "none",
      //           height: "20px",
      //           marginY: "auto",
      //           boxSizing: "border-box",
      //         }}
      //         value={value}
      //         onChange={(e) => {
      //           setValue(e.target.value)
      //         }}
      //         onKeyDown={(e) => {
      //           if (e.key === "Enter" && value !== cellInfo.value) {
      //             this.setState({
      //               items: this.state.items.map((item) => {
      //                 if (item._id === cellInfo.original._id) {
      //                   item.cost = parseFloat(value) * item.quantity
      //                 }
      //                 return item
      //               }),
      //             })
      //           }
      //         }}
      //         onBlur={(e) => {
      //           if (value !== cellInfo.value) {
      //             this.setState({
      //               items: this.state.items.map((item) => {
      //                 if (item._id === cellInfo.original._id) {
      //                   item.cost = parseFloat(value) * item.quantity
      //                 }
      //                 return item
      //               }),
      //             })
      //           }
      //         }}
      //       />
      //     )
      //   },
      // },
      {
        Header: t("_quantity"),
        id: "quantity",
        accessor: (row) => row.Quantity || 1,
      },
      {
        Header: t("_total"),
        accessor: "Subtotal",
        Cell: (cellInfo) => {
          const [value, setValue] = useState(cellInfo.value)
          useEffect(() => {
            setValue(cellInfo.value, true)
          }, [cellInfo.value])

          return (
            <Input
              disabled={
                this.state.statuses.find(
                  (s) => s.customerId === cellInfo.original.CustomerId
                )?.status === "billed"
              }
              style={{
                outline: "none",
                backgroundColor: "inherit",
                border: "none",
                height: "20px",
                marginY: "auto",
                boxSizing: "border-box",
              }}
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && value !== cellInfo.value) {
                  this.setState({
                    items: this.state.items.map((item) => {
                      if (item._id === cellInfo.original._id) {
                        item.Subtotal = parseFloat(value)
                      }
                      return item
                    }),
                  })
                }
              }}
              onBlur={(e) => {
                if (value !== cellInfo.value) {
                  this.setState({
                    items: this.state.items.map((item) => {
                      if (item._id === cellInfo.original._id) {
                        item.Subtotal = parseFloat(value)
                      }
                      return item
                    }),
                  })
                }
              }}
            />
          )
        },
      },
    ]

    return (
      <>
        <div className="d-flex justify-content-between py-2">
          <h3>{t("_customerTotals")}</h3>
          <div
            className="d-flex"
            style={{
              gap: "10px",
            }}
          >
            <Dropdown
              isOpen={this.state.isDropdownOpen}
              toggle={this.toggleDropdown}
            >
              <DropdownToggle
                caret
                color="primary"
                disabled={
                  this.state.saving || this.state.selectedRows.length === 0
                }
              >
                {t("_billRows", {
                  chosenCustomers: this.state.selectedRows.length,
                  numCustomers: Object.keys(customerTotals).length,
                })}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>{t("_chooseBiller")}</DropdownItem>
                <DropdownItem
                  onClick={(e) => {
                    e.preventDefault()
                    this.chooseBiller("fivaldi", e)
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={(e) => this.chooseBiller("fivaldi", e)}
                  >
                    <span
                      style={{
                        width: "16px",
                        height: "16px",
                        borderRadius: "50%",
                        border: "2px solid #007bff",
                        backgroundColor: `${
                          this.state.billerChosen === "fivaldi"
                            ? "#007bff"
                            : "#fff"
                        }`,
                        marginRight: "8px",
                      }}
                    ></span>
                    Fivaldi
                  </div>
                </DropdownItem>
                <DropdownItem
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={(e) => this.chooseBiller("netsuite", e)}
                  >
                    <span
                      style={{
                        width: "16px",
                        height: "16px",
                        borderRadius: "50%",
                        border: "2px solid #007bff",
                        backgroundColor: `${
                          this.state.billerChosen === "netsuite"
                            ? "#007bff"
                            : "#fff"
                        }`,
                        marginRight: "8px",
                      }}
                    ></span>
                    NetSuite
                  </div>
                </DropdownItem>
                <DropdownItem>
                  <Button
                    color="primary"
                    block
                    size="sm"
                    disabled={this.state.billerChosen === ""}
                    style={{ marginTop: "10px" }}
                    onClick={this.submitBilling}
                  >
                    {t("_bill")}
                  </Button>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Button
              color="primary"
              disabled={
                this.state.calculating || this.state.selectedRows.length === 0
              }
              onClick={() => this.calculateRows(this.state.selectedRows)}
            >
              {t("_calculateRows", {
                chosenCustomers: this.state.selectedRows.length,
                numCustomers: Object.keys(customerTotals).length,
              })}
            </Button>
            <Button
              color="primary"
              disabled={this.state.calculating}
              onClick={() => this.setState({ confirmActionModalOpen: true })}
            >
              {t("_calculateAllRows")}
            </Button>
            <ConfirmActionModal
              title={t("_calculateRowsConfirmModal")}
              message={t("_calculateRowsConfirmModalMessage")}
              open={this.state.confirmActionModalOpen}
              toggle={() =>
                this.setState({
                  confirmActionModalOpen: !this.state.confirmActionModalOpen,
                })
              }
              onConfirm={() => {
                this.setState({ confirmActionModalOpen: false })
                this.calculateRows("all")
              }}
            />
          </div>
        </div>
        <TranslatedTable
          data={combineByField(
            customerTotals,
            this.state.statuses,
            "customerId"
          )}
          fetching={
            this.state.fetching || this.state.calculating || this.state.saving
          }
          columns={columnsTotals}
          noDataText="No items"
          handleresize={this.props.setlistsize}
          listSize={this.props.list.listSetting}
          filterable={true}
          isSelect
          keyField="customerId"
          isSelected={(row) => this.state.selectedRows.includes(row)}
          selectAll={this.state.selectedAll}
          SelectInputComponent={SelectInputComponent}
          toggleAll={() => {
            if (this.state.selectedAll) {
              // deselect all
              this.setState({
                selectedAll: !this.state.selectedAll,
                selectedRows: [],
              })
            } else {
              // select all data that is not filtered
              this.setState({
                selectedAll: !this.state.selectedAll,
                selectedRows: removeDuplicates([
                  ...this.reactTable.current.reactTable.current.wrappedInstance.state.sortedData
                    .filter((x) => x.status !== "billed")
                    .map((x) => x._original.customerId),
                  ...this.state.selectedRows,
                ]),
              })
            }
          }}
          toggleSelection={(row) => {
            const rowId = row.match(/select-(.*)/)[1]
            this.setState({
              selectedRows: this.state.selectedRows.includes(rowId)
                ? this.state.selectedRows.filter((r) => r !== rowId)
                : [...this.state.selectedRows, rowId], // match after select-
              selectedAll:
                this.state.selectedRows.length +
                  (this.state.selectedRows.includes(rowId) ? -1 : 1) ===
                this.state.statuses.filter((x) => x.status !== "billed").length,
            })
          }}
          ref={this.reactTable}
          expandable
        />
        <hr />
        <h3>{t("_items")}</h3>
        <TranslatedTable
          data={this.state.items}
          fetching={this.state.fetching}
          columns={columnsItems}
          noDataText="No items in invoice"
          handleresize={this.props.setlistsize}
          listSize={this.props.list.listSetting}
          filterable={true}
          customButton={
            <Button
              disabled={this.state.savingRows}
              onClick={this.saveRows}
              color="primary"
              className="float-right"
            >
              {t("_save")}
            </Button>
          }
        />
        <hr />

        <Modal
          isOpen={this.state.modalOpen}
          toggle={() =>
            this.setState((prevState) => ({
              modalOpen: !prevState.modalOpen,
            }))
          }
          centered={true}
        >
          <ModalBody>
            {t(this.state.modalMessage, this.state.modalOptions)}
          </ModalBody>
        </Modal>

        <hr />
      </>
    )
  }
}

export default connect((s) => s, { setlistsize, fail, fetchResources })(
  withTranslation()(InvoiceDetail)
)
